import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Carousel from "nuka-carousel"

const ElthamPage = ({ data }) => (
  <Layout>
    <SEO title="Our Eltham Project" />
    <h1>Our Eltham Project</h1>

    <div className="flex justify-center">
      <div className="w-2/3 h-auto py-6">
        <Carousel renderBottomCenterControls={false}>
          {data.elthamPhotos.edges.map(edge => {
            return <Img fluid={edge.node.childImageSharp.fluid} />
          })}
        </Carousel>
      </div>
    </div>
  </Layout>
)

export default ElthamPage

export const query = graphql`
  query {
    elthamPhotos: allFile(
      filter: { 
        absolutePath: { regex: "/projects/residential/eltham/" }
        extension: { regex: "/(jpg)|(jpeg)/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
